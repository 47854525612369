<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2'">2ª VIA DO LAUDO</h3>
      <br /><br />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->

          <br />
        </div>

        <br /><br />
        <h3>ULTRASSONOGRAFIA PÉLVICA TRANSVAGINAL</h3>
        <br /><br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          {{ elemento }}
          <br />
          <br />
        </h4>

        <div v-else-if="elemento === 'EspaçoSeparar'">
          <p></p>
        </div>

        <div v-else-if="elemento === 'CaviUte'">
          <strong>Cavidade uterina:</strong>
          {{ laudo.cavidadeUterina }}
        </div>

        <div v-else-if="elemento === 'ConcUter'">
          <strong></strong>
          {{
            laudo.concUtero === 'Malformação uterina'
              ? laudo.concUtero + ' - ' + laudo.concUteroMalForm
              : laudo.concUtero
          }}
        </div>
        <div v-else-if="elemento === 'Endo'">
          <strong></strong>
          {{ laudo.condicaoUterina }}.
        </div>
        <div v-else-if="elemento === 'Diu'">
          <strong></strong>
          {{ laudo.diu }}.
        </div>
        <div v-else-if="elemento === 'Endo2'">
          <strong>Endométrio</strong> mede {{ laudo.endometrioMede }} mm de
          espessura{{ laudo.uteroContornos2 || laudo.uteroSituacao2 ? ', ' : ''
          }}{{ laudo.uteroSituacao2 ? laudo.uteroSituacao2 + ' ' : ''
          }}{{ laudo.uteroContornos2 ? laudo.uteroContornos2 : '' }}
        </div>
        <div v-else-if="elemento === 'Eco01'">
          <strong>Ecotextura</strong> {{ laudo.ecotextura
          }}{{
            laudo.imagemHiperecoicaMedindo || laudo.pediculoVascular
              ? ', apresentando imagem hiperecóica medindo'
              : ''
          }}
          {{
            laudo.imagemHiperecoicaMedindo
              ? laudo.imagemHiperecoicaMedindo + ' mm, '
              : ''
          }}
          {{ laudo.pediculoVascular ? laudo.pediculoVascular + '' : '' }}.
        </div>

        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />

      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>

    <!-- <p>
        O útero está {{ laudo.uteroPosicao }} , {{ laudo.uteroSituacao }} , tem
        os contornos {{ laudo.uteroContornos }}
      </p>

      <p>O miométrio tem ecotextura {{ laudo.miometrioEcotextura }}</p> -->
    <!--
      <p>
        Presença de {{ laudo.presenca }} imagens hipoecóicas no miométrio, assim
        distribuídas:
      </p> -->

    <!-- <p>
        {{ laudo.distribuicaoImagens }} medindo {{ laudo.medindo }} cm,
        localizado na parede {{ laudo.localizacao }}
      </p> -->

    <!-- {{ laudo.informacaoUtero1 }}<br>

      {{ laudo.informacaoUtero2 }}<br>

      {{ laudo.informacaoUtero3 }}<br>

      {{ laudo.informacaoUtero4 }}<br> -->
    <!--
      <p>Os diâmetros uterinos são:</p>

      <p>Longitudinal: {{ laudo.longitudinal }} cm.</p>

      <p>Transversal: {{ laudo.transversal }} cm.</p>

      <p>Antero-posterior: {{ laudo.anteroPosterior }} cm.</p>

      <p>Volume uterino: {{ laudo.volumeUterino }} cm³.</p>

      <p>Cavidade uterina: {{ laudo.cavidadeUterina }}</p> -->

    <!-- <p>
        O endométrio mede {{ laudo.endometrioMede }} mm de espessura,
        {{ laudo.uteroSituacao2 }} {{ laudo.uteroContornos2 }}
      </p>

      <p>Ecotextura: {{ laudo.ecotextura }}</p> -->

    <!-- <p>
        Imagem hiperecóica medindo {{ laudo.imagemHiperecoicaMedindo }}mm,
        {{ laudo.pediculoVascular }}
      </p> -->

    <!-- <p>
        Presença de DIU distando {{ laudo.presencaDiu }} do fundo da cavidade
        uterina

      {{ laudo.pediculoVascular2 }}</p> -->

    <!-- <p>
        Saco gestacional intracavitário de contornos {{ laudo.sacoGestacional }}
      </p> -->

    <!-- <p>Vesícula vitelínica {{ laudo.vesiculasVitelinica }}</p> -->

    <!-- <p>
        Embrião {{ laudo.sacoGestacional2 }} medindo {{ laudo.medindo2 }}mm.
      </p> -->

    <!-- O ovário direito mede {{ laudo.medidasOvarioDireito1 }}x{{
          laudo.medidasOvarioDireito2
        }}x{{ laudo.medidasOvarioDireito3 }} cm de diâmetros, com volume de
        {{ laudo.medidasOvarioDireito4 }}cm³. -->

    <!-- <p>
         {{ laudo.informacaoUtero5 }}
        <span
          v-if="
            laudo.informacaoUtero5 ===
            'aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
          "
        >
          {{ laudo.informacaoUtero5Cm }} no seu maior diâmetro.
        </span> -->

    <!-- <span
          v-if="
            laudo.informacaoUtero5 ===
            'aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo medindo'
          "
        >
          {{ laudo.informacaoUtero5Cm2 }} no seu maior diâmetro.
        </span> -->

    <!-- <span
          v-if="
            laudo.informacaoUtero5 ===
            'aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando finos septos medindo'
          "
        >
          {{ laudo.informacaoUtero5Cm3 }} no seu maior diâmetro.
        </span> -->

    <!-- <span
          v-if="
            laudo.informacaoUtero5 ===
            'aumentado à custa de imagem anecóica contendo debris medindo'
          "
        >
          {{ laudo.informacaoUtero5Cm4 }} no seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.informacaoUtero5 ===
            'com ecotextura difusamente heterogênea medindo'
          "
        >
          {{ laudo.informacaoUtero5Cm5 }} no seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.informacaoUtero5 ===
            'com ecotextura difusamente heterogênea medindo apresentando imagem heterogênea medindo'
          "
        >
          {{ laudo.informacaoUtero5Cm6 }} no seu maior diâmetro.
        </span>
      </p> -->

    <!-- O ovário esquerdo mede {{ laudo.medidasOvarioEsquerdo1 }}x{{
          laudo.medidasOvarioEsquerdo2
        }}x{{ laudo.medidasOvarioEsquerdo3 }} cm de diâmetros, com volume de
        {{ laudo.medidasOvarioEsquerdo4 }}cm³. -->

    <!-- <p>
        {{ laudo.informacaoUtero6 }}
        <span
          v-if="
            laudo.informacaoUtero6 ===
            'aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
          "
        >
          {{ laudo.informacaoUtero6Cm }} no seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.informacaoUtero6 ===
            'aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo medindo'
          "
        >
          {{ laudo.informacaoUtero6Cm2 }} no seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.informacaoUtero6 ===
            'aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando finos septos medindo'
          "
        >
          {{ laudo.informacaoUtero6Cm3 }} no seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.informacaoUtero6 ===
            'aumentado à custa de imagem anecóica contendo debris medindo'
          "
        >
          {{ laudo.informacaoUtero6Cm4 }} no seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.informacaoUtero6 ===
            'com ecotextura difusamente heterogênea medindo'
          "
        >
          {{ laudo.informacaoUtero6Cm5 }} no seu maior diâmetro.
        </span>

        <span
          v-if="
            laudo.informacaoUtero6 ===
            'com ecotextura difusamente heterogênea medindo apresentando imagem heterogênea medindo'
          "
        >
          {{ laudo.informacaoUtero6Cm6 }} no seu maior diâmetro.
        </span>
      </p> -->

    <!-- <p>
        {{ laudo.liquidoDouglas1 }} de líquido livre em fundo de saco de
        Douglas.
      </p> -->

    <!-- <p>{{ laudo.liquidoDouglas2 }} de líquido livre em região pélvica.</p> -->

    <!-- {{ laudo.presencaImagem }} {{ laudo.liquidoDouglas }}, medindo
        {{ laudo.imagemHeterogeneaCm }}x{{ laudo.imagemHeterogeneaCm2 }}x{{
          laudo.imagemHeterogeneaCm3
        }}cm de diâmetros, com volume de {{ laudo.imagemHeterogeneaCm4 }}cm³.


      </folha>

      <folha>

      <p><h4>Conclusão:</h4></p>

      <p>{{laudo.pelveSemAlteracao}}</p>

      <p>{{laudo.condicaoUterina}}</p>

      <p>{{laudo.achadosSugestivos}}</p>

      <p>{{laudo.aumentoVolumetrico}}</p>

      <p>{{laudo.liquidoIntracavitario}}</p>

      <p>{{laudo.conteudoHeterogeneo}}</p>

      <p>Imagem hiperecóica intracavitária com pedículo vascular, sugestiva de {{laudo.imagemHiperecóica}}</p>

      <p>{{laudo.endometrioEspessado}}</p>

      <p>{{laudo.gestacao}}</p>

      <p>{{laudo.sugestaoAvaliacao}}</p>

      <p>{{laudo.sugestaoDiu}}</p>

      <p>{{laudo.gravidezEctópica}}</p>

      <p>Cisto simples {{laudo.cistoSimples}}</p>

      <p>Cisto com debris (cisto hemorrágico) {{laudo.cistoDebris}}</p>

      <p>Cisto complexo {{laudo.cistoComplexo}}</p>

      <p>Achados sugestivos de endometrioma {{laudo.achadoEndometrioma}}</p>

      <p>{{laudo.tumorSolido}} {{laudo.tumorSolido2}}</p>

      <p>{{laudo.ovariosAumentados}}</p>

      </folha> -->
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],
  mounted() {
    let folha = [];
    if (this.$route.query.via) this.via = this.$route.query.via;
    if (this.laudo.opcoesUtero)
      folha.push(
        `${
          this.laudo.opcoesUtero === 'Paciente menacme' ||
          this.laudo.opcoesUtero === 'Paciente no menacme'
            ? (this.laudo.dataUltimaMestrucao
                ? ' Data da última menstruação ' +
                  this.dataFormatada(this.laudo.dataUltimaMestrucao)
                : '') +
              (this.laudo.dataUltimaMestrucao
                ? ', ' +
                  this.diaCicloMenstrual(this.laudo.dataUltimaMestrucao) +
                  'º dia do ciclo menstrual '
                : '')
            : this.laudo.opcoesUtero
        }.`
      );

    if (this.laudo.uteroPosicao)
      folha.push(`O útero está ${this.laudo.uteroPosicao}, ${this.laudo.uteroSituacao}, tem
        os contornos ${this.laudo.uteroContornos}`);

    if (this.laudo.miometrioEcotextura)
      folha.push(
        `O miométrio tem ecotextura ${this.laudo.miometrioEcotextura}${
          this.laudo.miometrioEcotextura2
            ? ' ' + this.laudo.miometrioEcotextura2
            : ''
        }`
      );

    if (this.laudo.comentariosAdcionaisUter01)
      folha.push(`${this.laudo.comentariosAdcionaisUter01}`);

    var numPresenca = parseInt(this.laudo.presenca);
    if (this.laudo.presenca)
      folha.push(
        `Presença de ${
          numPresenca === 1
            ? 'um'
            : numPresenca === 2
            ? 'dois'
            : numPresenca === 3
            ? 'três'
            : numPresenca === 4
            ? 'quatro'
            : numPresenca === 5
            ? 'cinco'
            : ''
        }
      ${
        this.laudo.presenca > 1 ? ' nódulos miometriais' : ' nódulo miometrial'
      } com as seguintes características:`
      );

    // if (this.laudo.presenca == 1)
    //   folha.push(
    //     `Presença de um ${
    //       this.laudo.presenca > 1
    //         ? ' nódulos miometriais'
    //         : ' nódulo miometrial'
    //     } com as seguintes características:`
    //   );
    // if (this.laudo.presenca == 2)
    //   folha.push(
    //     `Presença de dois ${
    //       this.laudo.presenca > 1
    //         ? ' nódulos miometriais'
    //         : ' nódulo miometrial'
    //     } com as seguintes características:`
    //   );
    // if (this.laudo.presenca == 3)
    //   folha.push(
    //     `Presença de três ${
    //       this.laudo.presenca > 1
    //         ? ' nódulos miometriais'
    //         : ' nódulo miometrial'
    //     } com as seguintes características:`
    //   );
    // if (this.laudo.presenca == 4)
    //   folha.push(
    //     `Presença de quatro ${
    //       this.laudo.presenca > 1
    //         ? ' nódulos miometriais'
    //         : ' nódulo miometrial'
    //     } com as seguintes características:`
    //   );
    // if (this.laudo.presenca == 5)
    //   folha.push(
    //     `Presença de cinco ${
    //       this.laudo.presenca > 1
    //         ? ' nódulos miometriais'
    //         : ' nódulo miometrial'
    //     } com as seguintes características:`
    //   );

    // if (this.laudo.presenca === 1) {
    //   folha.push(
    //     'Presença de um nódulo miometrial com as seguintes características:'
    //   );
    // }

    //   if (this.laudo.presenca == 2)
    //     folha.push(
    //       'Presença de dois nódulo miometrial com as seguintes características:'
    //     );
    // }
    if (!!this.laudo.listaPresencas) {
      if (this.laudo.listaPresencas.length > 0)
        for (var i = 0; i < this.laudo.listaPresencas.length; i++) {
          folha.push(` Nódulo ${i + 1} - ${
            this.laudo.listaPresencas[i].presenca.distri
              ? this.laudo.listaPresencas[i].presenca.distri
              : ''
          }${this.laudo.listaPresencas[i].presenca.loc ? ' ' : ''}${
            this.laudo.listaPresencas[i].presenca.loc
              ? this.laudo.listaPresencas[i].presenca.loc
              : '.'
          }
          ${this.laudo.listaPresencas[i].presenca.distri ? ' medindo ' : ''}
            ${
              this.laudo.listaPresencas[i].presenca.medida
                ? this.laudo.listaPresencas[i].presenca.medida
                : ''
            }${this.laudo.listaPresencas[i].presenca.medida ? ' cm.' : ''}${
            (this.laudo.listaPresencas[i].presenca.medida ||
              this.laudo.listaPresencas[i].presenca.distri) &&
            this.laudo.listaPresencas[i].presenca.loc
              ? ''
              : ''
          }${this.laudo.listaPresencas[i].presenca.medida ? ' ' : ''}${
            (this.laudo.listaPresencas[i].presenca.medida ||
              this.laudo.listaPresencas[i].presenca.distri) &&
            this.laudo.listaPresencas[i].presenca.figo
              ? 'FIGO ' + this.laudo.listaPresencas[i].presenca.figo + '.'
              : ''
          }
`);
        }
    }

    if (this.laudo.distribuicaoImagens)
      folha.push(`${this.laudo.distribuicaoImagens} medindo ${this.laudo.medindo} cm,
        localizado na parede ${this.laudo.localizacao}.`);
    if (this.laudo.informacaoUteroFund)
      folha.push(`${this.laudo.informacaoUteroFund}. `);
    if (this.laudo.informacaoUtero1)
      folha.push(`${this.laudo.informacaoUtero1}. `);
    if (this.laudo.informacaoUtero2)
      folha.push(`${this.laudo.informacaoUtero2}. `);
    if (this.laudo.informacaoUtero3)
      folha.push(`${this.laudo.informacaoUtero3}. `);
    if (this.laudo.informacaoUtero4)
      folha.push(`${this.laudo.informacaoUtero4}.`);
    if (
      this.laudo.longitudinal ||
      this.laudo.transversal ||
      this.laudo.volumeUterino ||
      this.laudo.volumeUterino
    )
      folha.push(`Diâmetros uterinos`);
    if (this.laudo.longitudinal)
      folha.push(`Longitudinal: ${this.laudo.longitudinal} cm. `);
    if (this.laudo.anteroPosterior)
      folha.push(`Antero-posterior: ${this.laudo.anteroPosterior} cm. `);
    if (this.laudo.transversal)
      folha.push(`Transverso: ${this.laudo.transversal} cm. `);

    if (this.laudo.volumeUterino)
      folha.push(`Volume uterino: ${this.laudo.volumeUterino} cm³. `);
    folha.push(`EspaçoSeparar`);

    if (this.laudo.endometrioMede) folha.push(`Endo2`);

    if (
      this.laudo.longitudinal ||
      this.laudo.transversal ||
      this.laudo.volumeUterino ||
      this.laudo.volumeUterino
    )
      if (this.laudo.ecotextura) folha.push(`Eco01`);

    // if (this.laudo.ecotextura)
    //   folha.push(`Ecotextura: ${this.laudo.ecotextura}, apresentando imagem hiperecóica medindo ${
    //     this.laudo.imagemHiperecoicaMedindo} ${this.laudo.pediculoVascular ? 'mm. ' : ''}${
    //     this.laudo.pediculoVascular ? this.laudo.pediculoVascular : ''
    //   }.`);

    //   if (this.laudo.imagemHiperecoicaMedindo)
    //   folha.push(`,apresentando imagem hiperecóica medindo ${
    //     this.laudo.imagemHiperecoicaMedindo
    //   }

    // ${this.laudo.pediculoVascular ? 'mm. ' : ''}${
    //     this.laudo.pediculoVascular ? this.laudo.pediculoVascular : ''
    //   }.`);

    if (this.laudo.cavidadeUterina) folha.push(`CaviUte`);
    // folha.push(`EspaçoSeparar`);

    if (this.laudo.presencaDiu)
      folha.push(
        `DIU ${this.laudo.presencaDiu} ${
          this.laudo.pediculoVascular2 ? ' ' + this.laudo.pediculoVascular2 : ''
        }.`
      );
    // if (this.laudo.pediculoVascular2)
    //   folha.push(`${this.laudo.pediculoVascular2}.`);

    if (this.laudo.comentariosAdcionaisUter02)
      folha.push(`${this.laudo.comentariosAdcionaisUter02}`);

    if (this.laudo.sacoGestacional)
      folha.push(
        `Saco gestacional intracavitário de contornos ${this.laudo.sacoGestacional}.`
      );

    if (this.laudo.vesiculasVitelinica)
      folha.push(`Vesícula vitelínica ${this.laudo.vesiculasVitelinica}.`);
    if (this.laudo.sacoGestacional2)
      folha.push(`Embrião ${this.laudo.sacoGestacional2}${
        this.laudo.medindo2 ? 'medindo ' : ''
      }${this.laudo.medindo2 ? this.laudo.medindo2 : ''}${
        this.laudo.medindo2 ? 'mm' : ''
      }
    .`);

    if (this.laudo.medidasOvarioDireito1) folha.push('Ovários');
    if (
      this.laudo.informacaoUtero5 == ' não visualizadas' ||
      this.laudo.informacaoUtero5 == ' não visualizados' ||
      this.laudo.informacaoUtero5 == ' não visualizado'
    ) {
      folha.push('Ovário direito não visualizado');
    } else if (this.laudo.medidasOvarioDireito1)
      folha.push(
        `Ovário direito mede: ${this.laudo.medidasOvarioDireito1} x ${
          this.laudo.medidasOvarioDireito2
        } x ${this.laudo.medidasOvarioDireito3} cm de diâmetros${
          this.laudo.medidasOvarioDireito4 ? ', com volume de ' : ''
        }${
          this.laudo.medidasOvarioDireito4
            ? this.laudo.medidasOvarioDireito4
            : ''
        }${this.laudo.medidasOvarioDireito4 ? ' cm³' : ''}${
          this.laudo.informacaoUtero5
            ? this.variaveisDoOvario.includes(this.laudo.informacaoUtero5) &&
              this.laudo.informacaoUtero5 != 'Outros'
              ? this.laudo.informacaoUtero5 +
                ' ' +
                this.laudo.informacaoUtero5Cm +
                ' cm'
              : this.laudo.informacaoUtero5 === 'Outros'
              ? ', ' + this.laudo.informacaoUteroOutros
              : this.laudo.informacaoUtero5
            : ''
        }.`
      );

    // if (this.laudo.informacaoUtero5)
    // folha.push(`${this.variaveisDoOvario.includes(this.laudo.informacaoUtero5) && this.laudo.informacaoUtero5 != 'Outros' ?this.laudo.informacaoUtero5 + ' ' + this.laudo.informacaoUtero5Cm + ' cm':(this.laudo.informacaoUtero5 === 'Outros'? this.laudo.informacaoUteroOutros:this.laudo.informacaoUtero5)}.`);
    if (
      this.laudo.informacaoUtero6 == ' não visualizadas' ||
      this.laudo.informacaoUtero6 == ' não visualizados' ||
      this.laudo.informacaoUtero6 == ' não visualizado'
    ) {
      folha.push(`Ovário esquerdo não visualizado`);
    } else if (this.laudo.medidasOvarioEsquerdo1)
      folha.push(
        `Ovário esquerdo mede: ${this.laudo.medidasOvarioEsquerdo1} x ${
          this.laudo.medidasOvarioEsquerdo2
        } x ${this.laudo.medidasOvarioEsquerdo3} cm de diâmetros${
          this.laudo.medidasOvarioEsquerdo4 ? ', com volume de ' : ''
        }${
          this.laudo.medidasOvarioEsquerdo4
            ? this.laudo.medidasOvarioEsquerdo4
            : ''
        }${this.laudo.medidasOvarioEsquerdo4 ? ' cm³' : ''}${
          this.laudo.informacaoUtero6
            ? this.variaveisDoOvario.includes(this.laudo.informacaoUtero6) &&
              this.laudo.informacaoUtero6 != 'Outros'
              ? this.laudo.informacaoUtero6 +
                ' ' +
                this.laudo.informacaoUtero6Cm +
                ' cm'
              : this.laudo.informacaoUtero6 === 'Outros'
              ? ', ' + this.laudo.informacaoUteroOutros6
              : this.laudo.informacaoUtero6
            : ''
        }.`
      );

    if (this.laudo.comentariosAdcionaisOvarios)
      folha.push(`${this.laudo.comentariosAdcionaisOvarios}`);

    // if (this.laudo.informacaoUtero6)
    //   folha.push(`${this.variaveisDoOvario.includes(this.laudo.informacaoUtero6) && this.laudo.informacaoUtero6 != 'Outros' ?this.laudo.informacaoUtero6 + ' ' + this.laudo.informacaoUtero6Cm + ' cm':(this.laudo.informacaoUtero6 === 'Outros'? this.laudo.informacaoUteroOutros6:this.laudo.informacaoUtero6)}.`);

    folha.push(`EspaçoSeparar`);
    if (this.laudo.liquidoDouglas1)
      folha.push(`${
        this.laudo.liquidoDouglas1
      } de líquido livre em fundo de saco de
        Douglas. ${
          this.laudo.liquidoDouglas1 === 'Presença' &&
          !!this.laudo.presencaLiqDou
            ? this.laudo.presencaLiqDou
            : ''
        }`);
    if (this.laudo.presencaImagem)
      folha.push(`${this.laudo.presencaImagem} ${
        this.laudo.liquidoDouglas
      }, medindo
           ${this.laudo.imagemHeterogeneaCm} x ${
        this.laudo.imagemHeterogeneaCm2
      } x
          ${this.laudo.imagemHeterogeneaCm3} cm de diâmetros${
        this.laudo.imagemHeterogeneaCm4 ? ', com volume de ' : ''
      }${
        this.laudo.imagemHeterogeneaCm4 ? this.laudo.imagemHeterogeneaCm4 : ''
      }${this.laudo.imagemHeterogeneaCm4 ? ' cm³' : ''}.`);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.utero = [...folha];
    folha = [];
    if (this.laudo.concUtero) folha.push(`ConcUter`);
    if (this.laudo.comentariosAdcionaisUter)
      folha.push(`${this.laudo.comentariosAdcionaisUter}`);
    if (this.laudo.pelveSemAlteracao)
      folha.push(`${this.laudo.pelveSemAlteracao}.`);
    if (this.laudo.condicaoUterina) folha.push(`Endo`);
    if (this.laudo.comentariosAdcionaisCondcUter)
      folha.push(`${this.laudo.comentariosAdcionaisCondcUter}`);
    if (this.laudo.diu) folha.push(`Diu`);
    if (this.laudo.comentariosAdcionaisDIU)
      folha.push(`${this.laudo.comentariosAdcionaisDIU}`);

    if (this.laudo.cavidadeUm) folha.push(`${this.laudo.cavidadeUm}`);
    if (this.laudo.cavidadeDois) folha.push(`${this.laudo.cavidadeDois}`);
    if (this.laudo.comentariosAdcionaisCavidade)
      folha.push(`${this.laudo.comentariosAdcionaisCavidade}`);

    // folha.push(`EspaçoSeparar`);
    if (this.laudo.ovarioConc) folha.push(``);
    if (this.laudo.ovarioConc) folha.push(`${this.laudo.ovarioConc}.`);
    if (this.laudo.comentariosAdcionaisOvarioConc)
      folha.push(`${this.laudo.comentariosAdcionaisOvarioConc}`);
    if (this.laudo.chanceIOTA)
      folha.push(
        `${
          this.laudo.chanceIOTA
            ? 'IOTA – chance de benignidade basal ' +
              this.laudo.chanceIOTA +
              '%.'
            : ''
        }${
          this.laudo.chanceIOTABenin
            ? ' Chance de benignidade ajustado ' +
              this.laudo.chanceIOTABenin +
              '%. '
            : ''
        }`
      );
    if (this.laudo.orads)
      folha.push(`${this.laudo.orads ? 'O-RADS ' + this.laudo.orads : ''}.`);
    if (this.laudo.comentariosAdcionaisIOTA)
      folha.push(`${this.laudo.comentariosAdcionaisIOTA}`);
    if (this.laudo.achadosSugestivos)
      folha.push(`${this.laudo.achadosSugestivos}.`);
    if (this.laudo.aumentoVolumetrico)
      folha.push(`${this.laudo.aumentoVolumetrico}.`);
    if (this.laudo.liquidoIntracavitario)
      folha.push(`${this.laudo.liquidoIntracavitario}.`);
    if (this.laudo.conteudoHeterogeneo)
      folha.push(`${this.laudo.conteudoHeterogeneo}.`);
    if (this.laudo.imagemHiperecóica)
      folha.push(
        `Imagem hiperecóica intracavitária com pedículo vascular, sugestiva de ${this.laudo.imagemHiperecóica}`
      );
    if (this.laudo.endometrioEspessado)
      folha.push(`${this.laudo.endometrioEspessado}.`);
    if (this.laudo.gestacao) folha.push(`${this.laudo.gestacao}.`);
    if (this.laudo.sugestaoAvaliacao)
      folha.push(`${this.laudo.sugestaoAvaliacao}`);
    if (this.laudo.sugestaoDiu) folha.push(`${this.laudo.sugestaoDiu}`);
    if (this.laudo.gravidezEctópica)
      folha.push(`${this.laudo.gravidezEctópica}.`);
    if (this.laudo.cistoSimples)
      folha.push(`Cisto simples ${this.laudo.cistoSimples}`);
    if (this.laudo.cistoDebris)
      folha.push(
        `Cisto com debris (cisto hemorrágico) ${this.laudo.cistoDebris}`
      );
    if (this.laudo.cistoComplexo)
      folha.push(`Cisto complexo ${this.laudo.cistoComplexo}`);
    if (this.laudo.achadoEndometrioma)
      folha.push(
        `Achados sugestivos de endometrioma ${this.laudo.achadoEndometrioma}`
      );
    if (this.laudo.tumorSolido)
      folha.push(` ${this.laudo.tumorSolido} ${this.laudo.tumorSolido2}`);
    if (this.laudo.ovariosAumentados)
      folha.push(` ${this.laudo.ovariosAumentados}`);

    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusao = [...folha];
    folha = [];

    const todos = [...this.utero, ...this.conclusao];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 32));
    }
  },
  data() {
    return {
      utero: [],
      via: '',
      conclusao: [],
      listaPresencas: [],

      tamanhoElemento: [],
      titulos: ['Conclusão', 'Diâmetros uterinos', 'Ovários'],
      variaveisDoOvario: [
        ', apresentando imagem em vidro fosco, medindo',
        ', apresentando cisto com debris medindo',
        ', apresentando cisto com contornos regulares e paredes finas, medindo',
        ', apresentando cisto com fino septo, medindo',
        ', apresentando cisto com septos, perfazendo menos de 10 loculações, medindo',
        ', apresentando cisto com septos, perfazendo mais de 10 loculações, medindo',
        ', apresentando imagem heterogênea medindo',
      ],
    };
  },
  methods: {
    diaCicloMenstrual(ultimaMenstruacao) {
      const hoje = moment();
      const inicioCiclo = moment(ultimaMenstruacao);
      const duracaoCiclo = 28; // duração do ciclo menstrual em dias
      const diasDesdeInicioCiclo = hoje.diff(inicioCiclo, 'days');
      const diaCiclo = (diasDesdeInicioCiclo % duracaoCiclo) + 1;
      return diasDesdeInicioCiclo.toFixed(0);
    },
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  h4 {
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -5px;

    margin-top: 5px;
    line-height: 6px;
    padding: 5px;
  }

  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }

  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    line-height: 5px;
  }

  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
